<template>
  <v-row class="pa-5">
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
      <v-col cols="12" class="mt-3">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <span class="text-h4"> Encaminhmentos para telemedicina </span>
            </v-row>
            <v-row no-gutters>
              <span class="caption font-weight-light pt-0 pb-0">
                Atendimentos que necessitaram de marcação de consulta
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table :headers="alertHeaders" :items="alerts" item-key="id">
        <template v-slot:item.menu="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="
                    $router.push({
                      name: 'PatientShowAttendance',
                      params: {
                        id: item.patient_id,
                        attendance_id: item.id,
                        data: item,
                      },
                    })
                  "
                >
                  <v-list-item-title
                    >Subir na lista de espera
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data: () => ({
    alerts: [],
    patient: {},
  }),
  mounted() {
    this.getAlerts();
  },
  methods: {
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    async getAlerts() {
      try {
        const response = await this.$axios("alerts", {
          params: {
            unattended  : true,
          },
        });
        
        const firstElement = response.data.shift();
        console.log ('Primeiro alerta' + firstElement);

        if (response.data) {
          response.data.forEach(
            (el) => (el.created_at = this.formatLastUpdate(el.created_at))
          );

          this.alerts = response.data;
        }
        console.log("alertasss:" + this.alerts);

      } catch (error) {
        console.log("mensagem de error" + error);
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    alertHeaders() {
      return [
        { text: "Paciente", value: "user.name", align: "center" },
        { text: "Motivo", value: "alertable_type", align: "center" },
        { text: "Tipo do alerta", value: "risk", align: "center" },
        { text: "Data e Horario do alerta", value: "created_at", align: "center" },
        { text: "Ação", value: "menu", width: "2%", align: "center" },
      ];
    },
  },
};
</script>

<style></style>
